import 'core-js/es6/map';
import 'core-js/es6/set';
import React from 'react';
import ReactDOM from 'react-dom';
import connect from '@vkontakte/vkui-connect';
import App from './App';
import registerServiceWorker from './sw';
import { BrowserRouter, Router, BrowserHistory } from 'react-router-dom';
import mVKMiniAppsScrollHelper from '@vkontakte/mvk-mini-apps-scroll-helper';
// import { createBrowserHistory } from  'history';
// var history = createBrowserHistory();

// history.listen(location => {
//   setTimeout(() => {

//     if (location.action === 'POP') {
//       return;
//     }
//     window.scrollTo(0, 0);
//   });
// });

// Init VK App
connect.send('VKWebAppInit', {});

// Если вы хотите, чтобы ваше веб-приложение работало в оффлайне и загружалось быстрее,
// расскомментируйте строку с registerServiceWorker();
// Но не забывайте, что на данный момент у технологии есть достаточно подводных камней
// Подробнее про сервис воркеры можно почитать тут — https://vk.cc/8MHpmT 
//  registerServiceWorker();

/* <BrowserRouter> 
	<App infoApp={window.location.search.substring(1).split('&')}/> 
</BrowserRouter>),  */
const root = document.getElementById('root');
mVKMiniAppsScrollHelper(root);
ReactDOM.render((
<BrowserRouter>
	<App infoApp={window.location.search.substring(1).split('&')}/> 
</BrowserRouter>), root);
