import React from 'react';
//import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
//import { withRouter } from 'react-router-dom';
//import { BrowserRouter as Redirect } from "react-router-dom";

import './img/ImgStyle.css';
import connect from '@vkontakte/vkui-connect';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { Offline, Online } from "react-detect-offline";

class Propodbor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			load: true,
			path: null,
			phone: '',
			log: null,
			value: null,
			is_allowed: true,
			banner: false,
			getNumber: true,
			connect: false,
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		connect.subscribe((e) => {
			switch (e.detail.type) {
				case 'VKWebAppGetPhoneNumberResult':
					this.setState({ phone: e.detail.data.phone_number.slice(1) });
					break;
				default:
					console.log(e.detail.type);
			}
		});
		// connect.send('VKWebAppGetUserInfo', {});
		// this.isAllowed();
	}
	Getphonenumber = () => {

		if (this.state.getNumber) {
			connect.send("VKWebAppGetPhoneNumber", {});
			this.setState({ getNumber: false })
		}

	}
	// isAllowed=()=>{
	// 	let self=this;
	// 	axios.get('https://franchise.irsib.pro/BackEnd/API.php', {
	// 				params: {
	// 					api: 7,
	// 				//	userid: this.props.user.id,
	// 			}
	// 				}).then(function (response) {
	// 					self.setState({is_allowed:response.data.response.is_allowed})
	// 				});
	// }
	Prorequest = () => {
		this.setState({ connect: true })

		// this.Banner();
		axios.get('https://franchise.irsib.pro/BackEnd/API.php', {
			params: {
				username: this.props.user.first_name,
				userlastname: this.props.user.last_name,
				user_phone: this.state.phone,
				//	userid:this.props.user.id,
				access: this.props.access,
				api: 15
			}
		}).then(function (response) {
			// console.log(response.data);
		});

	}
	// Pushes=()=>{
	// 	if(!this.state.is_allowed) connect.send('VKWebAppAllowNotifications', {});
	// 	else connect.send('VKWebAppDenyNotifications', {});
	// 	this.isAllowed();
	// 	}

	Redirtofilter = () => {
		this.setState({ path: '/filters' });
	}
	Redirtostart = () => {
		this.setState({ path: '/franchise' });
	}
	Banner = () => {
		this.setState({ banner: !this.state.banner })
		// console.log(this.state.phone)
	}
	render() {
		let alert;
		var banner;
		var phonecorrect = false;
		let fixed;


		if (this.state.banner) fixed = "fixed"
		else fixed = "";

		if (this.state.phone) {
			if (this.state.phone.indexOf("*") == -1) phonecorrect = true

		}


		if (this.state.banner && !this.state.connect && (phonecorrect))
			banner = <div className='b-popup'>
				<div className='banner '>
					{/* <div style={{textAlign:"center",background: "#FFFFFF",marginTop:"30%",padding:"40px"}}> */}
					<br />
					<h2>Вы уверены, что хотите отправить заявку?</h2>
					<br /><br /><br />
					<div className='buttonStyle wrap' onClick={this.Prorequest}><b>Отправить</b></div><br />
					<div className='buttonStyleSenler wrap' onClick={this.Banner}><b>Отменить</b></div>
				</div></div>
		// </div>
		else if (this.state.banner && this.state.connect && (phonecorrect))
			banner = <div className='b-popup'>
				<div className='banner '>
					{/* <div style={{textAlign:"center",background: "#FFFFFF",marginTop:"30%",padding:"40px"}}> */}
					<br />
					<h2>Ваша заявка успешно отправлена!</h2>
					<p>Мы свяжемся с Вами в ближайшее время</p>
					<div className='buttonStyleSenler wrap' onClick={this.Banner}><b>Продолжить</b></div>
				</div></div>
		else if (this.state.banner && !phonecorrect)
			banner = <div className='b-popup'>
				<div className='banner '>
					{/* <div style={{textAlign:"center",background: "#FFFFFF",marginTop:"30%",padding:"40px"}}> */}
					<br />
					<h2>Некорректно указан номер!</h2>
					<p>Пожалуйста, введите номер</p>
					<div className='buttonStyleSenler wrap' onClick={this.Banner}><b>Ввести номер</b></div>
				</div></div>
		else banner = null;







		// if (this.state.banner)
		// banner = <div className='b-popup'>
		// <div style={{textAlign:"center",background: "#FFFFFF",marginTop:"30%",padding:"40px"}}>
		// <h2>Вы уверены, что хотите отправить заявку?</h2>
		// <div className='buttonStyle wrap'onClick={this.Prorequest}><b>Отправить</b></div><br/>
		// <div className='buttonStyleSenler wrap'onClick={this.Banner}><b>Оменить</b></div>

		// </div></div>
		// else banner = null;













		if (this.props.allowed) alert = 'alertOn'
		else alert = 'alertImg';

		if (!this.state.load)

			return (<div style={{ textAlign: "center" }} > <div class="lds-ring"><div></div><div></div><div></div><div></div></div> </div>)
		else
			var redirto;
		if (this.state.path) {
			if (this.state.path == '/franchise') {
				redirto = <Redirect to={{
					pathname: '/franchise',
					state: { id: this.props.location.state.back }
				}} />
			} else
				redirto = <Redirect to={this.state.path}></Redirect>
		}
		return (
			<div><Online>
				<div className={"unselectable" + fixed}>
					{redirto}
					{banner}

					<div className='header'>
						<div className='backImg'
							//onClick={this.Redirtostart}
							onClick={() => this.props.history.go(-1)}
						></div>
						<div className={alert} onClick={this.props.push}></div>
						{/* <div className='filterImg'onClick={this.Redirtofilter}></div> */}
						<Link className='filterImg' to="/filters"></Link>
					</div>
					<div className='headerpaddindstart'>

						<h1>PRO подбор франшизы и юридическая проверка</h1>
						<div style={{ textAlign: "center" }}>
							<div style={{ height: "458px", background: "#FFFFFF", boxShadow: "0px 25px 40px rgba(221, 219, 231, 0.5)", borderRadius: "21px", margin: "auto 13px -471px" }}></div>
							<div className='photo' style={{ display: "inline-block", backgroundImage: `url(${this.props.user.photo_200})`, backgroundSize: "cover" }}><div className='photo'></div></div>
							<p className='gray'>Приветствуем</p>
							<h2 className='namestyle'>{this.props.user.first_name}</h2>
							<p className='gray'>Телефон для связи с Вами</p>
							<NumberFormat type='tel' onClick={this.Getphonenumber} value={this.state.phone} onValueChange={(values) => { const { formattedValue, value } = values; this.setState({ phone: formattedValue }) }} className='phone' format="+7 (###) ###-####" placeholder="+7 (***) ***-****" mask="*" />
							<div className='buttonStyle wrap' onClick={this.Banner} style={{ textDecoration: "none" }}><b>Отправить заявку</b></div>
						</div>
					</div>
				</div>
			</Online>
				<Offline>
					<p className='offline'>Отсутствует соединение с интернетом</p>
					<div style={{ textAlign: "center" }} > <div className="lds-ring"><div></div><div></div><div></div><div></div></div> </div>
				</Offline>
			</div>
		);
	}
}
export default Propodbor;