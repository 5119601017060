import React from 'react';
import connect from '@vkontakte/vkui-connect';
//import { BrowserRouter as  Route, Switch } from "react-router-dom";
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import Start from "./components/Start";
import Propodbor from "./components/Propodbor";
import Connect from "./components/Connect";
import Filters from './components/Filters';
import Franchise from './components/Franchise';
import Findbase from './components/Findbase';
import Franch from './components/Franch';
//import Test from './components/Test2';
import axios from 'axios';
import { YMInitializer } from 'react-yandex-metrika';
import './components/img/ImgStyle.css';


class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fetchedUser: null,
			base: null,
			findbase: null,
			maxvalues: null,
			rubrics: null,
			load: false,
			test: null,
			path: null,
			stop: false,
			is_allowed: true,
			phone: null,
			rubs: null,
			minval: null,
			maxval: null,
			sort: null,
			infoApp: null,
			mininvest: null,
			franch: 0,
			findmode: false,
			hystoryOfLooks: [],
			market: '',
			countFranch: 9,

		};
	}

	componentDidMount() {

		connect.subscribe((e) => {
			switch (e.detail.type) {
				case 'VKWebAppGetUserInfoResult':
					this.setState({ fetchedUser: e.detail.data });
					this.Initinapp();
					this.isAllowed();
					//	console.log(e.detail.data)
					break;
				case 'VKWebAppAllowNotificationsResult':
					this.isAllowed();
					break;
				case 'VKWebAppGetPhoneNumberResult':
					this.setState({ phone: e.detail.data.phone_number });
					break;
				case 'VKWebAppDenyNotificationsResult':
					this.isAllowed();
					break;
				default:
					console.log(e.detail.type);
			}
		});
		connect.send("VKWebAppSetViewSettings", { "status_bar_style": "dark" });



		var a = this.props.infoApp[0].split('=');
		var temp = '{ "infoApp": { "' + a[0] + '": "' + a[1] + '"';
		for (let i = 1; i < this.props.infoApp.length; i++) {
			var a = this.props.infoApp[i].split('=');
			temp += ', "' + a[0] + '": "' + a[1] + '"'
		}
		temp += '} }'
		this.setState({ infoApp: temp });

		connect.send('VKWebAppGetUserInfo', {});
		let self = this;

		axios.get('https://franchise.irsib.pro/BackEnd/apifran.php', {
			params: {
				api: 10,
				access: JSON.parse(temp)
			}
		}).then(function (response) {
			self.setState({ findbase: response.data[0], base: response.data[0], rubrics: response.data[1], maxvalues: response.data[2], mininvest: response.data[3], load: true });
		});



	}

	changeCountFranch = (val) => {
		this.setState({ countFranch: val })
	}
	changeFranch = (index) => {
		// console.log('change',index)
		this.setState({ franch: index })
	}
	addHystoryOfLooks = (franch) => {
		let hystoryOfLooks = this.state.hystoryOfLooks;
		hystoryOfLooks.push(franch);
		this.setState({ hystoryOfLooks });
		console.log(hystoryOfLooks)
	}

	Initinapp = () => {
		let self = this;
		axios.get('https://franchise.irsib.pro/BackEnd/apifran.php', {
			params: {
				api: 11,
				access: JSON.parse(this.state.infoApp)
			}
		}).then(function (response) {
			self.setState({ market: response.data[1] })
			if (response.data[0] == "JoinGroup")
				connect.send("VKWebAppJoinGroup", { "group_id": 13193852 });

		});
	}

	isAllowed = () => {
		let self = this;
		axios.get('https://franchise.irsib.pro/BackEnd/apifran.php', {
			params: {
				api: 7,
				access: JSON.parse(this.state.infoApp),

			}
		}).then(function (response) {
			self.setState({ is_allowed: response.data.response.is_allowed })
		});
	}
	Pushes = () => {
		if (!this.state.is_allowed) 
			connect.send('VKWebAppAllowNotifications', {});
		else 
			connect.send('VKWebAppDenyNotifications', {});
		this.isAllowed();
	}
	DelFindBase = () => {
		this.setState({ findbase: this.state.base, rubs: null, minval: null, maxval: null, sort: null, findmode: false });
	}

	FindBase = (rubs, min, max, sort) => {
		this.setState({ stop: true, rubs: rubs, minval: min, maxval: max, sort: sort })

		let self = this;
		axios.get('https://franchise.irsib.pro/BackEnd/apifran.php', {
			params: {
				access: JSON.parse(this.state.infoApp),

				api: 3,
				minv: min,
				maxv: max,
				rubs: rubs,
				sort: sort,
			}
		}).then(function (response) {
			self.setState({ findbase: response.data, stop: false, findmode: true });
		});
	}





	render() {

		if (this.state.load)

			return (
				<div>

					<YMInitializer accounts={[53751493]} />

					<Switch location={this.props.location}>
						<Route exact path="/" render={props => (<Start {...props} key='start' allowed={this.state.is_allowed} delFindBase={this.DelFindBase} push={this.Pushes} changeFranch={this.changeFranch} stop={this.state.stop} base={this.state.findbase} user={this.state.fetchedUser} market={this.state.market} changeCountFranch={this.changeCountFranch} countFranch={this.state.countFranch} access={JSON.parse(this.state.infoApp)} />)} />
						<Route exact path="/pro" render={props => (<Propodbor {...props} key='pro' allowed={this.state.is_allowed} push={this.Pushes} phone={this.state.phone} user={this.state.fetchedUser} access={JSON.parse(this.state.infoApp)} />)} />
						<Route exact path="/connect" render={props => (<Connect {...props} key='connect' allowed={this.state.is_allowed} push={this.Pushes} phone={this.state.phone} base={this.state.findbase} franch={this.state.franch} user={this.state.fetchedUser} access={JSON.parse(this.state.infoApp)} />)} />
						<Route exact path="/Filters" render={props => (<Filters {...props} key='filters' allowed={this.state.is_allowed} push={this.Pushes} base={this.state.findbase} user={this.state.fetchedUser} mininvest={this.state.mininvest} maxvalues={this.state.maxvalues} find={this.FindBase} test={this.state.test} rubs={this.state.rubrics} thisrubs={this.state.rubs} thisminval={this.state.minval} thismaxval={this.state.maxval} thissort={this.state.sort} access={JSON.parse(this.state.infoApp)} />)} />
						<Route exact path="/Franchise" render={props => (<Franchise {...props} key='franchise' allowed={this.state.is_allowed} push={this.Pushes} base={this.state.findbase} changeFranch={this.changeFranch} franch={this.state.franc} user={this.state.fetchedUser} maxvalues={this.state.maxvalues} access={JSON.parse(this.state.infoApp)} />)} />
						<Route exact path="/Franch" render={props => (<Franch {...props} key='franch' allowed={this.state.is_allowed} push={this.Pushes} base={this.state.findbase} changeFranch={this.changeFranch} franch={this.state.franch} user={this.state.fetchedUser} maxvalues={this.state.maxvalues} access={JSON.parse(this.state.infoApp)} hystoryOfLooks={this.state.hystoryOfLooks} addHystoryOfLooks={this.addHystoryOfLooks} />)} />
						<Route exact path="/Find" render={props => (<Findbase {...props} key='find' allowed={this.state.is_allowed} findmode={this.state.findmode} delFindBase={this.DelFindBase} push={this.Pushes} changeFranch={this.changeFranch} stop={this.state.stop} base={this.state.findbase} user={this.state.fetchedUser} access={JSON.parse(this.state.infoApp)} />)} />

					</Switch>


				</div>
			);
		else return (<div style={{ textAlign: "center" }} > <div className="lds-ring"><div></div><div></div><div></div><div></div></div> </div>)

	}
}

export default App;
