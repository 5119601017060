import React from 'react';
//import PropTypes from 'prop-types';
//import connect from '@vkontakte/vkui-connect';
//import { redirtowserRouter as Redirect } from "react-router-dom";
import { redirtowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";

//import { redirtowserRouter as Redirect } from "react-router-dom";
//import { withRouter } from 'react-router-dom';
import Post from './Post';
import './img/ImgStyle.css';
//import axios from 'axios';
import ym from 'react-yandex-metrika';
import { Offline, Online } from "react-detect-offline";



class Findbase extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			banner: false,
			posts: [],
			step: 9,
			frid: [],
			path: null,
			pathid: null,
			load: false,

		}
	}

	componentDidMount() {
		if (this.props.findmode) {
			this.CreatePostWithoutBackend();
			this.setState({ load: true })

		}
		document.addEventListener('scroll', this.trackScrolling);
		//	this.isAllowed();
	}
	componentDidUpdate(prevProps) {
		if (this.props.base != prevProps.base) {
			this.CreatePostWithoutBackend();
			this.setState({ load: true })

		}

	}
	componentWillUnmount() {
		document.removeEventListener('scroll', this.trackScrolling);
	}


	isBottom = (el) => {
		return el.getBoundingClientRect().bottom <= window.innerHeight;
	}

	trackScrolling = () => {
		const wrappedElement = document.getElementById('bott');
		if (this.isBottom(wrappedElement)) {
			this.CreatePostWithoutBackend();
			// console.log("bottom")
		}
	};
	Banner = () => {
		this.setState({ banner: !this.state.banner })
	}
	Redirtofranchise = (id) => {
		// this.props.changeFranch
		// console.log("id to franch",id)
		//document.removeEventListener('scroll', this.trackScrolling);
		this.setState({ path: '/Franchise', pathid: id });
		//console.log( id.name);
		ym('reachGoal', 'lookfr', { look_franchise: id.name });
	}

	Redirtofranch = (id) => {
		// console.log(id);
		this.props.changeFranch(id);
		// this.setState({path:'/Franch'});
		ym('reachGoal', 'lookfr', { look_franchise: this.props.base[id].name });
	}

	Redirtofilter = () => {
		//document.removeEventListener('scroll', this.trackScrolling);
		this.setState({ path: '/filters' });

	}

	CreatePostWithoutBackend = () => {
		let step = this.state.step;
		let base;
		if (this.props.findbase)
			base = this.props.findbase;
		else base = this.props.base;

		let posts = [];

		// console.log(base.length);
		if (base.length > 0) {
			if (step <= base.length) {
				for (let i = 0; i < step; i++) {
					posts.push(
						<Link className='post' key={i} to="/Franch" onClick={() => this.Redirtofranch(i)}>
							<Post id={base[i].id} date={base[i].date} name={base[i].name} top={base[i].top} investment={base[i].investment} />
						</Link>

						//  <div key={i} className='post' onClick={()=>this.Redirtofranchise(base[i])}>
						//  <Post id={base[i].id} date={base[i].date} name={base[i].name} top={base[i].top} investment={base[i].investment}/>
						//  </div>
					)
				};
				if (posts.length != 0) {
					this.setState({ posts: posts });
					this.setState({ step: this.state.step + 4 })
				} else this.setState({ posts: null });
			}
			else {
				for (let i = 0; i < base.length; i++) {
					posts.push(
						<Link className='post' key={i} to="/Franch" onClick={() => this.Redirtofranch(i)}>

							<Post id={base[i].id} date={base[i].date} name={base[i].name} top={base[i].top} investment={base[i].investment} />

						</Link>
						// <div key={i} className='post' onClick={()=>this.Redirtofranchise(base[i])}>
						// <Post id={base[i].id} date={base[i].date} name={base[i].name} top={base[i].top} investment={base[i].investment}/>
						// </div>
					)
				};
				if (posts.length != 0) {
					this.setState({ posts: posts });
					this.setState({ step: this.state.step })
				} else this.setState({ posts: null });

			}
		} else {
			// console.log(base);
			this.setState({
				posts: <div className='addPost' onClick={this.Redirtofilter}>
					<br />
					<br />
					<p>К сожалению, таких франшиз не найдено</p>
				</div>
			});
		}
	}

	render() {
		let banner;
		let redirto;
		let alert;
		let fixed;


		if (this.props.allowed) alert = 'alertOn'
		else alert = 'alertImg';

		if (this.state.banner) {
			// 			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			// 				banner = <div className='b-popup'>
			// 				<div className='banner '>
			// 				<div className='closeBanner' onClick={this.Banner}></div>
			// 				<h1>{this.props.user.first_name},</h1>
			// 				<h2>Хотите добавить свою франшизу? </h2>
			// 				<p>Чтобы добавить свою франшизу, свяжитесь с нами</p>
			// 				<a href='https://vk.com/im?media=&sel=-13193852' className="unselectable" style={{textDecoration: "none"}}>
			// 				<div className='buttonStyle wrap'><b>Связаться с нами</b></div>
			// 				</a>
			// 				</div></div>
			//   } else {
			banner = <div className='b-popup'>
				<div className='banner '>
					<div className='closeBanner' onClick={this.Banner}></div>
					<h1>{this.props.user.first_name},</h1>
					<h2 style={{ marginTop: "15px" }}>Хотите добавить свою франшизу? </h2>
					<p>Чтобы добавить свою франшизу, свяжитесь с нами</p>
					<a target="_blank" href='https://vk.com/im?media=&sel=-13193852' style={{ textDecoration: "none" }}>
						<div className='buttonStyle wrap'><b>Связаться с нами</b></div>
					</a>
				</div></div>
			// }


		}
		else banner = null;

		if (this.state.banner) fixed = "fixed"
		else fixed = "";


		if (this.state.path) {
			if (this.state.pathid) {
				redirto = <Redirect to={{
					pathname: '/franchise',
					state: { id: this.state.pathid }
				}} />
				//			console.log( this.state.pathid.name);
			} else redirto = <Redirect to={this.state.path}></Redirect>
		}
		if (!this.state.load) return (<div style={{ textAlign: "center" }} > <div className="lds-ring"><div></div><div></div><div></div><div></div></div> </div>)
		else

			var showmore;
		if (this.props.findbase) {
			if (this.state.posts.length < this.props.findbase.length) {
				showmore = <div className='showmore' id='bott' onClick={() => this.CreatePostWithoutBackend(this.state.step)}></div>;
				document.addEventListener('scroll', this.trackScrolling);

			} else { showmore = null; document.removeEventListener('scroll', this.trackScrolling); }
		} else if (this.state.posts.length < this.props.base.length) {
			showmore = <div className='showmore' id='bott' onClick={() => this.CreatePostWithoutBackend(this.state.step)}></div>;
			document.addEventListener('scroll', this.trackScrolling);

		} else { showmore = null; document.removeEventListener('scroll', this.trackScrolling); }
		let findbasedel;

		if (this.props.findbase) findbasedel = <div className='backImg'
			onClick={this.props.delFindBase}>
		</div>
		else findbasedel = null;
		return (
			<div><Online>

				<div className={'unselectable start ' + fixed}>
					{redirto}

					<div className='header'>
						<div className='backImg'
							//onClick={this.props.delFindBase}
							onClick={() => {
								this.props.delFindBase();
								this.props.history.goBack()
							}}

						>
						</div>
						<div className={alert} onClick={this.props.push}></div>
						<div className='filterImg'
							onClick={this.Redirtofilter}
						></div>

					</div>
					<div className='headerpaddindstart'>

						<div className='justi'>
							<div className='addPost' onClick={this.Banner}>
								<div className='addplus'></div>
								<p>Добавить свою франшизу</p>
							</div>



							{this.state.posts}
						</div>
						{showmore}
						{/* <div className='showmore' id='bott' onClick={()=>this.CreatePostWithoutBackend(this.state.step)}></div> */}
						{banner}
					</div>
				</div>
			</Online>
				<Offline>
					<p className='offline'>Отсутствует соединение с интернетом</p>
					<div style={{ textAlign: "center" }} > <div className="lds-ring"><div></div><div></div><div></div><div></div></div> </div>
				</Offline>
			</div>
		);
	}
}
export default Findbase;