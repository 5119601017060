import React from 'react';
//import PropTypes from 'prop-types';
//import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
//import { withRouter } from 'react-router-dom';
import './Style.css';
import NumberFormat from 'react-number-format';

class Post extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: null,
			investment: null,
		}
	}

	componentDidMount() {

	}

	render() {
		var bg = "url('https://franchise.irsib.pro/pics/" + this.props.id + "logo.jpeg')";
		var top;
		var newBase;
		let date = new Date(this.props.date);
		let today = new Date()
		if (date < today - (10 * (24 * 60 * 60 * 1000)))
			newBase = null;
		else if (isNaN(date) === false)
			newBase = <div className="wrap newfr"><p>NEW</p></div>;
		if (this.props.top === '1') top = <div className="wrap bestfr"><p>Лучшее</p></div>;
		return (
			<div >
				{newBase}
				<div className='backpost'></div>

				<div>
					<div className='pict' style={{ backgroundImage: bg, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}></div>
				</div>
				<div className='postInfo'>
					<div className='namePost wrap'><b style={{ wordBreak: "break-word" }}> {this.props.name}</b></div>
					<div className='investments'>Инвестиции</div>
					<div className='cash'><b><NumberFormat displayType='text' value={this.props.investment} thousandSeparator=' ' /> p</b></div>


					{top}
				</div>
			</div>
		);
	}
}
export default Post;