import React from 'react';
//import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
//import { BrowserRouter as Redirect } from "react-router-dom";

//import { withRouter } from 'react-router-dom';
import Post from './Post'
import './img/ImgStyle.css';
import InputRange from 'react-input-range';
import './slidermini.css'
import axios from 'axios';
//import connect from '@vkontakte/vkui-connect';
import ym from 'react-yandex-metrika';
import NumberFormat from 'react-number-format';
import { Offline, Online } from "react-detect-offline";



class Franchise extends React.Component {
constructor(props) {
	super(props);
	this.state = {
		user:null,
		id:null,
		name:null,
		about:null,
		lump_sum:null,
		investment:null,
		royalty:null,
		profit:null,
		royaltycomment:null,
		yOfFoundation:null,
		yOfFronchise:null,
		description:null,
		posts:[],
		path: null,
		pathif: null,
		maxroyalty:null,
		maxinvestment:null,
		maxprofit:null,
		maxlump:null,
		maxpoints:null,
		maxpayback:null,
		link_to_fr:null,
		request:null,
		look:null,
		back:[],
		load:true,
		history:null,
		is_allowed:true,
		optional_button : null,
		optional_button_link : null,
		payback_period:null,
		fr_points:null,
		}
	}

	componentDidMount() {
			this.setState({
			maxroyalty:this.props.maxvalues[0][0],
			maxprofit:this.props.maxvalues[0][1],
			maxinvestment:this.props.maxvalues[0][2],
			maxlump:this.props.maxvalues[0][3],
			maxpoints:this.props.maxvalues[0][4],
			maxpayback:this.props.maxvalues[0][5],
		});
	if(this.props.location.state.back){
	}
	else this.Refrash(this.props.location.state.id);
}

	Redirtopropodbor=()=>{
		this.setState({path:'/pro'});
	}
	Redirtoconnect=()=>{
		this.setState({path:'/connect'});
		axios.get('https://franchise.irsib.pro/BackEnd/API.php', {
			params: {
			api: 'req', frid:this.state.id,
			access:this.props.access
			}
			});
	}
	Redirtofilter=()=>{
		this.setState({path:'/filters'});
	}
	Redirtostart=()=>{
		// let back=[];
		// let id;
		// back=this.state.back;
		// id=back[back.length-1];
		// if(back.length==0)
		this.setState({path:'/'});
		// else{
		// 	back.pop();
		// 	this.setState({back:back,load:true});
		// 	this.Refrash(id);
		// }
	}

	Redirtofranchise=(id)=>{
		// let back = [];
		// back=this.state.back;
		// back.push(this.state.history);
		// this.setState({back:back,posts:null,load:false});
		// console.log(id);
		this.setState({posts:null,load:false});
		this.Refrash(id);
		ym('reachGoal', 'lookfr',{look_franchise:id.name});

	}

	Refrash=(id)=>{
		// console.log("refr",id);
		axios.get('https://franchise.irsib.pro/BackEnd/API.php', {
			params: {
			api: 'look', frid:id.id,
			access:this.props.access
			}
			});
		this.setState({
		history:id,
		id : id.id,
		name : id.name,
		about : id.about,
		lump_sum : id.lump_sum,
		investment : id.investment,
		royalty :id.royalty,
		profit : id.profit,
		yOfFoundation : id.year_of_foundation,
		yOfFranchise : id.year_of_franchise,
		description : id.description,
		royaltycomment: id.royalty_comment,
		look : id.look,
		request : id.request,
		link_to_fr : id.link_to_fr,
		optional_button : id.Optional_button,
		optional_button_link : id.Optional_button_link,
		payback_period:id.payback_period,
		fr_points:id.fr_points,
		load:true,
		});
		window.scrollTo(0, 0);
		this.ToBackEnd(id.id);
	}

	
	ToBackEnd=(id)=>{
	var self=this;
	var ids=[];
		axios.get('https://franchise.irsib.pro/BackEnd/API.php', {
			params: {
			api: 5, frid:id,
			access:this.props.access

			}
			}).then(function (response) {
				for(let thisid=0; thisid<response.data.length; thisid++)
				ids.push(response.data[thisid].id);
				self.CreateFirstPost(ids);
				self.setState({frid: ids});
			});
		}
		
CreateFirstPost =(ids)=>{
	let posts=[];
	let step;
	let base=this.props.base;
	let shortbase=[];
	if(ids.length<=6) step=ids.length
	else step=6
		for(let i=0;i<ids.length;i++){
		 let cut=base.find(c => c.id === ids[i]);
		shortbase.push(cut);
		}
		for (let i =0; i < step; i++) {
			posts.push(<div key={i} className='post' onClick={()=>this.Redirtofranchise(shortbase[i])}>
			<Post id={shortbase[i].id} name={shortbase[i].name} investment={shortbase[i].investment}/>
			</div>)
		};
		
	this.setState({posts:posts});
	
}


	render() {
		let looklike;
		let redirto;
		var logo;
		var pic;
		var comma;
		let alert;
		if(this.state.posts){
		if(this.state.posts.length!==0)looklike=<h2 style={{margin:"10px 20px"}}>Похожие франшизы</h2>;
		else looklike=null;
		}else looklike=null;
		var invest, profit,lump,royalty,points,payback,yoff,yofc;
			if(this.state.investment>=0)
				invest=<div className='frinfo'>
						<p>Объём инвестиций:</p>
						<InputRange  maxValue={parseInt(this.state.maxinvestment,10)} minValue={1} step={1}  value={parseInt(this.state.investment,10)}/>
						<h6><NumberFormat displayType='text' value={this.state.investment} thousandSeparator=' ' /> р </h6>
					</div>;
			else invest=null;
			if(this.state.profit>=0)
				profit=<div className='frinfo'>
						<p>Прибыль:</p>
						<InputRange  maxValue={parseInt(this.state.maxprofit,10)} minValue={1} step={1}  value={parseInt(this.state.profit,10)}/>
						<h6> от <NumberFormat displayType='text' value={this.state.profit} thousandSeparator=' ' /> р/мес. </h6>
					</div>;
			else profit=null;
			if(this.state.lump_sum>=0)
				lump=<div className='frinfo'>
						<p>Паушальный взнос:</p>
						<InputRange  maxValue={parseInt(this.state.maxlump,10)} minValue={1} step={1}  value={parseInt(this.state.lump_sum,10)}/>
						<h6><NumberFormat displayType='text' value={this.state.lump_sum} thousandSeparator=' ' /> р</h6>
					</div>;
			else lump=null;
			if(this.state.royalty>=0)
				royalty=<div className='frinfo'>
						<p>Роялти:</p>
						<InputRange  maxValue={parseInt(this.state.maxroyalty,10)} minValue={0} step={1}  value={parseInt(this.state.royalty,10)}/>
						<h6>{this.state.royalty}%{comma} {this.state.royaltycomment}</h6>
					</div>;
			else royalty=null;
			if(this.state.fr_points>=0)
				points=<div className='frinfo'>
						<p>Франчайзинговых точек:</p>
						<InputRange  maxValue={parseInt(this.state.maxpoints,10)} minValue={1} step={1}  value={parseInt(this.state.fr_points,10)}/>
						<h6>Всего открыто: {this.state.fr_points}</h6>
					</div>;
			else points=null;
			if(this.state.payback_period>=0)
				payback=<div className='frinfo'>
						<p>Срок окупаемости франшизы:</p>
						<InputRange  maxValue={parseInt(this.state.maxpayback,10)} minValue={1} step={1}  value={parseInt(this.state.payback_period,10)}/>
						<h6>{this.state.payback_period} мес.</h6>
					</div>;
			else payback=null;
			if(this.state.yOfFoundation>=1000)
				yofc=<div className='frinfo'>
						<p>Год основания компании:</p>
						<h6>{this.state.yOfFoundation}г.</h6>
					</div>;
			else yofc=null
			if(this.state.yOfFranchise>=1000)
				yoff=<div className='frinfo'>
						<p>Год запуска франшизы:</p>
						<h6>{this.state.yOfFranchise}г. </h6>
					</div>;
			else yoff=null;

		if(this.props.allowed) alert='alertOn'
		else alert='alertImg';
		if(!this.state.load)
			return(<div style={{textAlign:"center"}} > <div className="lds-ring"><div></div><div></div><div></div><div></div></div> </div>)
		else
		if(this.state.royaltycomment) comma=','
		if(this.state.id){
		logo="url('https://franchise.irsib.pro/pics/"+this.state.id+"logo.jpeg')";
		pic="url('https://franchise.irsib.pro/pics/"+this.state.id+"pic.jpeg')";
//		picgr="linear-gradient(180deg, rgba(255, 255, 255, 0) 29.28%, rgba(81, 57, 239, 0.5) 100%)";
		}
		if(this.state.path) {
			if(this.state.pathid){
				redirto=<Redirect to={{
				pathname: '/franchise',
				state: { id: this.state.pathid }
				}}/>
			}
			else if(this.state.path=='/connect'){
				redirto=<Redirect to={{
				pathname: '/connect',
				state: { id: this.state.id ,name:this.state.name, opt_link:this.state.optional_button_link, opt_name:this.state.optional_button, back:this.state.history, link_to_fr:this.state.link_to_fr}
				}}/>
			}
			else if(this.state.path=='/pro'){
				redirto=<Redirect to={{
				pathname: '/pro',
				state: { id: this.state.id ,name:this.state.name, back:this.state.history}
				}}/>
			}
			else redirto=<Redirect to={this.state.path}></Redirect>
		}

		return (
			<div><Online>

			<div className="unselectable">
				{redirto}
				
			<div className='header'>
				<div className='backImg' onClick={this.Redirtostart}></div>
				<div className={alert} onClick={this.props.push}></div>
				<div className='filterImg'onClick={this.Redirtofilter}></div>
			</div>
			<div className='headerpaddindstart' style={{textAlign :"center"}}>
				<div className='bigscreen'>
					<div className='picback'></div>
					<div className='franchisepic'style ={{backgroundImage:pic, backgroundSize:"cover" ,backgroundPosition:"center", backgroundRepeat:"no-repeat"}}><div className='franchisepic'></div></div>
					<div className='biglogo' style ={{backgroundImage: logo, backgroundSize:"contain" ,backgroundPosition:"center", backgroundRepeat:"no-repeat"}}></div>
					<div className='visit'>
					<div style={{height:"1px"}}></div>
						<h2 className='visit'>{this.state.name}</h2>
						<p className='visit'>{this.state.about}</p>
						<div className='buttonStyle wrap' onClick={this.Redirtoconnect}><b>Связаться с франшизой</b></div>
					</div>
					<div style={{marginBottom:"25px"}}>
					<div style={{display: "inline-block", width:"50%", boxSizing:"border-box", textAlign:"center"}}><p style={{display: "inline-block", marginRight:"6px",opacity:"0.3"}}>Просмотры </p><div className='lookimg'></div> <p style={{display: "inline-block",opacity:"0.3"}}>{this.state.look}</p></div>
					<div style={{display: "inline-block", width:"50%", boxSizing:"border-box",textAlign:"center"}}><p style={{display: "inline-block", marginRight:"6px",opacity:"0.3"}}>Контакты </p><div className='reqimg'></div> <p style={{display: "inline-block",opacity:"0.3"}}>{this.state.request}</p></div>
					</div>
					<div className='infogradient'>
						{/*
					<div className='frinfo'>
						<p>Объём инвестиций:</p>
						<InputRange  maxValue={this.state.maxinvestment} minValue={1} step={1}  value={this.state.investment}/>
						<h6>{this.state.investment} р </h6>
					</div>
					<div className='frinfo'>
						<p>Прибыль:</p>
						<InputRange  maxValue={this.state.maxprofit} minValue={1} step={1}  value={this.state.profit}/>
						<h6> от {this.state.profit} р/мес. </h6>
					</div>
										<div className='frinfo'>
						<p>Паушальный взнос:</p>
						<InputRange  maxValue={this.state.maxlump} minValue={1} step={1}  value={this.state.lump_sum}/>
						<h6>{this.state.lump_sum} р</h6>
					</div>
					<div className='frinfo'>
						<p>Роялти:</p>
						<InputRange  maxValue={this.state.maxroyalty} minValue={0} step={1}  value={this.state.royalty}/>
						<h6>{this.state.royalty}%{comma} {this.state.royaltycomment}</h6>
					</div>
					<div className='frinfo'>
						<p>Франчайзинговых точек:</p>
						<InputRange  maxValue={this.state.maxpoints} minValue={1} step={1}  value={this.state.fr_points}/>
						<h6>Всего открыто: {this.state.fr_points}</h6>
					</div>
					<div className='frinfo'>
						<p>Срок окупаемости франшизы:</p>
						<InputRange  maxValue={this.state.maxpayback} minValue={1} step={1}  value={this.state.payback_period}/>
						<h6>{this.state.payback_period} мес.</h6>
					</div>
					<div className='frinfo'>
						<p>Год основания компании:</p>
						<h6>{this.state.yOfFoundation}г.</h6>
					</div>
					<div className='frinfo'>
						<p>Год запуска франшизы:</p>
						<h6>{this.state.yOfFranchise}г. </h6>
					</div>
						*/}
						{invest}
						{lump}
						{profit}
						{royalty}
						{points}
						{payback}
						{yofc}
						{yoff}
					</div>
				<br/>
					<h2 style={{margin:"10px 20px"}}>Описание франшизы</h2>
					<br/>
					<p style={{margin:"10px 20px"}} dangerouslySetInnerHTML={{__html:this.state.description}}></p>
					<br/>

					<div className='buttonStyle wrap' onClick={this.Redirtopropodbor}><b>PRO подбор франшизы и юридическая проверка</b></div>
					<br/>
					<br/>

					{/* <h2 style={{margin:"10px 20px"}}>Похожие франшизы</h2> */}
					{looklike}
					<div className='justi'>{this.state.posts}</div>
				</div>
			</div>
			</div>
			</Online>
			<Offline>
			<p className='offline'>Отсутствует соединение с интернетом</p>
			<div style={{textAlign:"center"}} > <div className="lds-ring"><div></div><div></div><div></div><div></div></div> </div>
			</Offline>
			</div>
			
		);
	}
}
export default Franchise;